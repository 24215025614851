var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-list',{attrs:{"color":"#e5e5e5"}},[_c('div',{attrs:{"color":"white"}},_vm._l((_vm.currentChat),function(item,index){return _c('v-list-item',{key:index,ref:_vm.objSearchMessage && item.id === _vm.objSearchMessage.id
          ? 'scrollToMe'
          : '',refInFor:true,class:_vm.objSearchMessage && item.id === _vm.objSearchMessage.id
          ? 'scrollToMe'
          : ''},[(item)?_c('v-layout',{attrs:{"justify-start":item.isExternal,"justify-end":!item.isExternal,"py-1":""},on:{"dblclick":function($event){return _vm.emitReplyMessage(item)}}},[_c('v-card',{staticClass:"px-1 py-1 position-relative",style:(_vm.radius7),attrs:{"max-width":"500px","min-width":"80px","color":item.isExternal ? 'white' : '#d9fdd3'},on:{"mousemove":function($event){(_vm.menuId = item.id), (_vm.showMenu = false)},"mouseleave":function($event){return _vm.mouseLeave()}}},[_c('div',{staticClass:"position-absolute top-right",staticStyle:{}},[(_vm.menuId === item.id || (_vm.menuId === item.id && _vm.showMenu))?_c('v-menu',{staticStyle:{},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"rgba(0,0,0, 0)","height":"20","left":"","elevation":"0","x-small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" expand_more ")])],1)]}}],null,true),model:{value:(_vm.showMenu),callback:function ($$v) {_vm.showMenu=$$v},expression:"showMenu"}},[_c('v-list',_vm._l((item.type === 'message'
                    ? _vm.menuItems
                    : _vm.menuAttachItems),function(menu,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.clickMenu(menu, item)}}},[_c('v-list-item-title',{staticStyle:{}},[_vm._v(" "+_vm._s(menu.title)+" ")])],1)}),1)],1):_vm._e()],1),_c('div',{staticClass:"d-flex flex-column-reverse"},[_c('div',{staticClass:"d-flex justify-space-between align-end"},[_c('span',[_vm._v(_vm._s(item.content)+" ")]),_c('span',{staticClass:"message_time"},[_vm._v(_vm._s(_vm.formatTimeToScreen(item.message_time)))])]),(item.quoted_message)?_c('div',{staticClass:"pl-3 mousePointer",style:(_vm.replyMessageBox(item)),attrs:{"elevation":"0"},on:{"click":function($event){return _vm.goToReplyMessage(item)}}},[_c('span',{staticClass:"font-weight-bold",style:(_vm.spanReplyMessage(item))},[_vm._v(" "+_vm._s(item.quoted_message.isExternal ? "Cliente" : "Você")+" ")]),_c('br'),_c('span',{staticClass:"d-inline-block text-truncate",staticStyle:{}},[_vm._v(" "+_vm._s(item.quoted_message.content)+" ")])]):_vm._e()])])],1):_vm._e()],1)}),1)])
}
var staticRenderFns = []

export { render, staticRenderFns }