<template>
  <v-card height="100%" rounded="lg">
    <v-card-title> Agentes </v-card-title>
    <v-card-text style="height: calc(100% - 50px)">
      <div class="d-flex flex-column justify-end fill-height">
        <v-card elevation="1" class="mb-4">
          <v-data-table
            no-results-text="Não há registro."
            no-data-text="Não há registro."
            loading-text="Carregando atendimentos..."
            :expanded.sync="expanded"
            :loading="loadingAgents"
            :items-per-page="10"
            hide-default-footer
            :items="agents"
            :page.sync="page"
            single-expand
            item-key="name"
            show-expand
            :headers="[
              {
                text: 'Agente',
                value: 'name',
              },
              {
                text: '',
                value: 'data-table-expand',
              },
            ]"
          >
            <template v-slot:[`item.name`]="{ item }">
              <td v-show="item">
                <div class="mb-0 pb-0 d-flex justify-space-between align-center">
                  <div>
                    <span class="mr-2">
                      {{ item.agent.name }}
                    </span>
                    <v-icon
                      :color="item.agent.state.color"
                      size="15"
                    >
                      circle
                    </v-icon>
                  </div>
                  <v-chip
                    small
                  >
                    {{ agentRequesters(item) }}
                  </v-chip>
                </div>
              </td>
            </template>
            <template v-slot:expanded-item="{ item }">
              <td colspan="12">
                <v-list dense color="transparent">
                  <v-list-item
                    v-for="(chat, i) in item.agent.chats.filter(chat => chat.state === 'open')"
                    :id="`c-${chat.ticket}-${i}`"
                    :key="`c-${chat.ticket}-${i}`"
                    class="px-0"
                  >
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ chat.name }}
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-menu bottom left>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon v-bind="attrs" v-on="on">
                            <v-icon>mdi-dots-vertical</v-icon>
                          </v-btn>
                        </template>

                        <v-list dense>
                          <div v-for="(menu, i) in agentMenu" :key="`m-${chat.ticket}-${i}`">
                            <v-list-item @click="menu.action" link>
                              <v-list-item-title>
                                {{ menu.name }}
                              </v-list-item-title>
                            </v-list-item>
                            <v-divider
                              v-if="
                                agentMenu.length > 1 && i < agentMenu.length - 1
                              "
                            />
                          </div>
                        </v-list>
                      </v-menu>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </td>
            </template>
          </v-data-table>
        </v-card>

        <v-pagination
          v-if="pageCount && pageCount > 0"
          class="mt-auto"
          :color="Boolean(banner) ? banner['page_bg_color'] : 'primary'"
          v-model="page"
          :length="pageCount && pageCount > 0 ? pageCount : 0"
        />
        <div v-else class="mt-auto" />
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "AgentCallCard",
  data: () => ({
    expanded: [],
    page: 1,
  }),
  methods: {
    agentRequesters(item) {
      if (Boolean(item)) {
        const requesters = (item.agent.chats.filter(chat => chat.state === 'open')).length;
        return requesters;
      } else {
        return 0;
      }
    },
  },
  computed: {
    ...mapGetters({
      loadingAgents: "Management/getLoadingAgents",
      agentMenu: "Management/getAgentMenu",
      agents: "Ws/getAgents",
      banner: "Panel/getBanner",
    }),
    pageCount() {
      if (this.agents.length > 0) {
        const numberOfPages = this.agents.length / 10;
        return Math.ceil(numberOfPages);
      } else {
        return 0;
      }
    },
  },
};
</script>
