import vuetify from '../plugins/vuetify'
import moment from 'moment'

var gFunc = {
  fonts: {
    poppins(fSize, hexColor, fWeight, lHeight, lSpacing){
      typeof fSize === "number" ? (fSize).toFixed(2) : 12.00
      hexColor = hexColor ? hexColor : '#FFFFFF'
      fWeight = fWeight ? fWeight : '600'
      lHeight = lHeight ? lHeight : '19'
      lSpacing = lSpacing ? lSpacing : '0'
      let font = "font-family: 'Poppins', sans-serif;"
      let fontSize = "font-size: " + fSize + "px;"
      let fontWeight = "font-weight: " + fWeight + ";"
      let lineHeight = "line-height: " + lHeight + "px;"
      let letterSpacing = 'letter-spacing: ' + lSpacing + "px;"
      let fontColor = "color: " + hexColor + ";"
      return font + fontSize + fontColor + fontWeight + lineHeight + letterSpacing
    }
  },

  rules: {
    required: value => !!value || 'Campo obrigatório.',
    validatePhone: value => {
      if (value && value.length > 0){
        value = value.replace(/[- ()]/g,'')
        return value.length === 11 ? true : 'Celular inválido.'
      } else {
        return true
      }
    },
    validateEmail: value => {
      const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(value) ? true : 'Email Inválido.'
    },
    validateCpf: value => {
      if(value){
        value = value.replace(/[.-]/g,'')
        var Soma
        var Resto
        Soma = 0
        let valorIgualQtd = 0
        let ultimoNumero = ''
        for (let i=0; i<value.length; i++){
          value[i] === ultimoNumero ? valorIgualQtd++ : valorIgualQtd = 0
          ultimoNumero = value[i]
        }
        if (valorIgualQtd === 10) return 'CPF inválido.'
      
        for (let i=1; i<=9; i++) Soma = Soma + parseInt(value.substring(i-1, i)) * (11 - i)
        Resto = (Soma * 10) % 11
      
          if ((Resto == 10) || (Resto == 11))  Resto = 0
          if (Resto != parseInt(value.substring(9, 10)) ) return 'CPF inválido.'
      
        Soma = 0;
          for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(value.substring(i-1, i)) * (12 - i)
          Resto = (Soma * 10) % 11
      
          if ((Resto == 10) || (Resto == 11))  Resto = 0
          if (Resto != parseInt(value.substring(10, 11) ) ) return 'CPF inválido.'
          return true
        } else {
          return ''
        }
      },

    validateCnpj: cnpj => {
      cnpj = cnpj.replace(/[^\d]+/g,'');
      if(cnpj == '') return false;
      if (cnpj.length != 14) return false;

      // Elimina CNPJs invalidos conhecidos
      if (cnpj == "00000000000000" ||
        cnpj == "11111111111111" ||
        cnpj == "22222222222222" ||
        cnpj == "33333333333333" ||
        cnpj == "44444444444444" ||
        cnpj == "55555555555555" ||
        cnpj == "66666666666666" ||
        cnpj == "77777777777777" ||
        cnpj == "88888888888888" ||
        cnpj == "99999999999999")
        return false;

      // Valida DVs
      var tamanho = cnpj.length - 2
      var numeros = cnpj.substring(0,tamanho);
      var digitos = cnpj.substring(tamanho);
      var soma = 0;
      var pos = tamanho - 7;
      for (var i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2)
              pos = 9;
      }
      var resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
      if (resultado != digitos.charAt(0)) return false;
      tamanho = tamanho + 1;
      numeros = cnpj.substring(0,tamanho);
      soma = 0;
      pos = tamanho - 7;
      for (i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) pos = 9;
      }
      resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
      if (resultado != digitos.charAt(1)) return false;
      return true;
    },

    validateNome: value => {
      const specialChars = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?0123456789]+/

      if(value && value.length > 0){
        if(value.length < 3){
          return 'Digite o nome completo.'
        }

        if(specialChars.test(value)){
          return 'O nome não deve conter números ou caracteres especiais.'
        }
      }

      return true
    },

    validateDate: value => {
      if(value && value.length > 0){
        value = value.replace(/[- /]/g,'')
        return (value.length === 8 && !isNaN(value)) ? true : 'Data inválida.'
      } else {
        return true
      }
      
    },

    validateCep: value => {
      if(value && value.length > 0){
        value = value.replace(/[- /]/g,'')
        return (value.length === 8 && !isNaN(value)) ? true : 'CEP inválido.'
      } else {
        return true
      }
    },

    digitsOnly: value => {
      return !isNaN(value) ? true : 'Apenas números.'
    },

    validatePassword: value => {
      let hasDigits = false 
      let hasUppercase = false
      let hasLowercase = false
      let hasSpecial = false
      const specialChars = ` !"#$%&'()*+,-./:;<=>?@[\]^_\`{|}~`

      if(value && value.length > 0){
        for (let index = 0; index < value.length; index++) {
          if(isNaN(value.charAt(index)) && (value.charAt(index) === value.charAt(index).toUpperCase())){
            hasUppercase = true
          }

          if(isNaN(value.charAt(index)) && (value.charAt(index) === value.charAt(index).toLowerCase())){
            hasLowercase = true
          }

          if(!isNaN(value.charAt(index))){
            hasDigits = true
          }

          if(specialChars.includes(value.charAt(index))){
            hasSpecial = true
          }
          
        }

        return (hasDigits && hasUppercase && hasLowercase && hasSpecial && value.length >= 8) ? true : 
          'A senha deve conter no mínimo 8 caracteres, incluindo letras minúsculas, maiúsculas, números e caracteres especiais.'
      
      } else {
        return true
      }

    }

    },

  isMobile(){
    return vuetify.framework.breakpoint.mobile
  },

  copyArray(arr){
    return JSON.parse(JSON.stringify(arr));
  },

  formatPhoneNumber(number){
    return `(${number.slice(0, 2)}) ${number.slice(2, 
        number.length == 11 ? 7 : 6)}-${number.slice(number.length == 11 ? 7 : 6)}`
  },

  fileExtensionFromBase64Str(base64Str){
    const extensions = {
      '/': 'jpg',
      'i': 'png',
      'R': 'gif',
      'U': 'webp'
    }

    const fileExtensionIdentifier = base64Str.charAt(0)
    return extensions[fileExtensionIdentifier]
  },

  b64toBlob(b64Data, contentType){
    const _contentType = contentType ? 
      contentType : this.fileExtensionFromBase64Str(b64Data)
    const sliceSize = 512
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
  
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
  
    const blob = new Blob(byteArrays, {type: _contentType});
    return blob;
  },

  async blobToOdooBase64(blob, resModel=""){
    if (!blob)
      return ''
    const toBase64 = file =>
      new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
      const anexa = async file => {
        let fileBase64 = await toBase64(file)
        fileBase64 = fileBase64.replace(/^data:.+;base64,/, '')

        const attachmentData = {
          name: file.name,
          datas_fname: file.name,
          type: file.type,
          filedatas: fileBase64,
          res_model: resModel,
        }
        return attachmentData
      }
    return anexa(blob)
  },

  FormatUtcToPtbr(date, time){
    let newDate = new Date(date)
    let formatedDate = new Date(newDate.valueOf() - newDate.getTimezoneOffset() * 60000)
    formatedDate = moment(formatedDate).locale("pt-br").format(`DD/MM/YYYY${time ? ' H:mm' : ''}`)
    return formatedDate
  },
}
export default gFunc;