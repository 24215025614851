import axios from "axios";
import store from "@/store";

const initialState = () => ({
  agents: [],
  queue: [],
  currentQueue: 0,
  queueSelected: 0,
  currentChat: null,
  status: {
    id: null,
    name: '',
    color: '#c1c1c1'
  },
  wsStatus: false
});
const state = initialState();
const getters = {
  getAgents: (state) => state.agents,
  getQueue: (state) => state.queue,
  getQueueSelected: (state) => state.queueSelected,
  getCurrentChat: (state) => state.currentChat,
  getCurrentStatus: (state) => state.status,
  getWsStatus: (state) => state.wsStatus,
  getCurrentQueue: (state) => state.currentQueue,
};
const actions = {
};
const mutations = {
  SET_AGENT_STATUS(state, payload) {
    state.status = payload;
  },
  CHANGE_AGENT_STATUS(state, { data }) {
    const agentIndex = state.agents.findIndex((element) => element.agent.id == data['agent']['id']);
    state.agents[agentIndex].agent.state = data['agent']['status']
  },
  SET_NEW_AGENT_IN_TEAM(state, { data }) {
    const agentIndex = state.agents.findIndex((element) => element.agent.id == data['agent']['id']);
    if(agentIndex > -1){
      state.agents[agentIndex].agent.state = data['agent']['state']
    }
  },
  SET_ALL_AGENTS(state, { data }) {
    state.agents = data;
  },
  SET_QUEUE(state, { data }) {
    state.queue = data;
  },

  APPEND_CHAT_TO_AGENT(state, { data }) {
    const indexAgent = state.agents.findIndex(
      (el) => el.agent.id == data.agent.id
    );
    if (indexAgent > -1) {
      data.agent.chat.messages = [];
      state.agents[indexAgent].agent.chats.push(data.agent.chat);
      if (this.currentChat === null) {
        state.currentChat = state.agents[indexAgent].agent.chats[-1];
      }
    }
  },
  UPDATE_TICKETS_QUEUE(state, { data }) {
    const teamIndex = state.queue.findIndex(
      (el) => el.team.id === data.team_id
    );
    if (teamIndex > -1) state.queue[teamIndex].team.tickets.push(data.ticket);
  },
  GET_TICKET_CHAT_MESSAGES(state, { data }) {
    const wsAgentId = data.team_id.ticket.agent_id;
    const wsTicketId = data.team_id.ticket.id;
    const agentIndex = state.agents.findIndex(
      (el) => el.agent.id === wsAgentId
    );
    const ticketIndex = state.agents[agentIndex].agent.chats.findIndex(
      (el) => el.id === wsTicketId
    );
    data.team_id.ticket.messages.map((item) => (item.ticket_id = wsTicketId));
    state.agents[agentIndex].agent.chats[ticketIndex].messages =
      data.team_id.ticket.messages;

    if (state.currentChat === null) {
      if (Boolean(data.team_id.ticket.state === "open"))
        state.currentChat = data.team_id.ticket.messages;
    }
  },
  SET_QUEUE_SELECTED(state, { chatId, userAgentId }) {
    state.currentChat = null;

    const agentIndex = state.agents.findIndex(
      (el) => el.agent.id === userAgentId
    );

    const myChats = state.agents[agentIndex].agent.chats.filter((el) => el.state === "open");

    const queue = myChats.findIndex(
      (chat) => chat.id === chatId
    );

    state.queueSelected = queue;
  },
  SET_WS_STATUS(state, { status }) {
    state.wsStatus = status
  },
  SET_AGENT_MESSAGE_SENDED(state, { data }) {
    const wsAgentId = data.team_id.ticket.agent_id;
    const wsTicketId = data.team_id.ticket.id;
    const agentIndex = state.agents.findIndex(
      (el) => el.agent.id === wsAgentId
    );
    const ticketIndex = state.agents[agentIndex].agent.chats.findIndex(
      (el) => el.id === wsTicketId
    );
    state.agents[agentIndex].agent.chats[ticketIndex].messages.push(
      data.team_id.ticket.message
    );
    state.currentChat =
      state.agents[agentIndex].agent.chats[ticketIndex].messages;
  },
  UPDATE_TICKET_CHAT_MESSAGE(state, { data }) {
    const wsTicketId = data.ticket.id;
    const agentIndex = state.agents.findIndex((el) =>
      el.agent.chats.some((item) => item.id === wsTicketId)
    );
    const ticketIndex = state.agents[agentIndex].agent.chats.findIndex(
      (el) => el.id === wsTicketId
    );
    state.agents[agentIndex].agent.chats[ticketIndex].messages.push(
      data.ticket.message
    );
  },
  CLOSE_TICKET(state, { data }) {
    state.currentChat = null;
    const wsTicketId = data.team.ticket.id;
    const agentIndex = state.agents.findIndex((el) =>
      el.agent.chats.some((item) => item.id === wsTicketId)
    );
    const ticketIndex = state.agents[agentIndex].agent.chats.findIndex(
      (el) => el.id === wsTicketId
    );
    state.agents[agentIndex].agent.chats[ticketIndex].state = data.team.ticket.state;

    // Remove ticker from queue list
    const indexTeam = state.queue.findIndex((el) => el.team.id == data.team.id);
    const indexQueue = state.queue[indexTeam].team.tickets.findIndex(
      (el) => el.id == data.team.ticket.id
    );
    if (indexQueue > -1)
      state.queue[indexTeam].team.tickets.splice(indexQueue, 1);
  },
  ASSIGN_SERVICE_TO_AGENT(state, { data }) {
    // console.log('ASSIGN_SERVICE_TO_AGENT')
  },
  UPDATE_TICKET_STATE(state, { data }) {
    state.currentChat = null;
    const wsTicketId = data.team.ticket.id;
    const queueIndex = state.queue.findIndex((el) => el.team.id === data.team.id);

    if (queueIndex > -1){
      const tickets = state.queue.find((el) => el.team.id === data.team.id).team.tickets
      const ticketIndex = tickets.findIndex((el) => el.id === wsTicketId);

      if (ticketIndex > -1){
        state.queue[queueIndex].team.tickets[ticketIndex].state = data.team.ticket.state;
        state.queue[queueIndex].team.tickets[ticketIndex].agent = data.team.ticket.agent;
        state.queue[queueIndex].team.tickets[ticketIndex].duration = data.team.ticket.duration;
      }
    }
  },
  SET_CURRENT_QUEUE(state, { teamId }) {
    const queueIndex = state.queue.findIndex(queue => queue.team.id === teamId)
    state.currentQueue = queueIndex
  },
  PING(state) {
    state.wsStatus = true
  },
  CLEAN(state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },
};
export default {
  actions,
  state,
  getters,
  mutations,
  namespaced: true,
};
