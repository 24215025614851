var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"height":"100%","rounded":"lg"}},[_c('v-card-title',[_vm._v(" Agentes ")]),_c('v-card-text',{staticStyle:{"height":"calc(100% - 50px)"}},[_c('div',{staticClass:"d-flex flex-column justify-end fill-height"},[_c('v-card',{staticClass:"mb-4",attrs:{"elevation":"1"}},[_c('v-data-table',{attrs:{"no-results-text":"Não há registro.","no-data-text":"Não há registro.","loading-text":"Carregando atendimentos...","expanded":_vm.expanded,"loading":_vm.loadingAgents,"items-per-page":10,"hide-default-footer":"","items":_vm.agents,"page":_vm.page,"single-expand":"","item-key":"name","show-expand":"","headers":[
            {
              text: 'Agente',
              value: 'name',
            },
            {
              text: '',
              value: 'data-table-expand',
            },
          ]},on:{"update:expanded":function($event){_vm.expanded=$event},"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:`item.name`,fn:function({ item }){return [_c('td',{directives:[{name:"show",rawName:"v-show",value:(item),expression:"item"}]},[_c('div',{staticClass:"mb-0 pb-0 d-flex justify-space-between align-center"},[_c('div',[_c('span',{staticClass:"mr-2"},[_vm._v(" "+_vm._s(item.agent.name)+" ")]),_c('v-icon',{attrs:{"color":item.agent.state.color,"size":"15"}},[_vm._v(" circle ")])],1),_c('v-chip',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.agentRequesters(item))+" ")])],1)])]}},{key:"expanded-item",fn:function({ item }){return [_c('td',{attrs:{"colspan":"12"}},[_c('v-list',{attrs:{"dense":"","color":"transparent"}},_vm._l((item.agent.chats.filter(chat => chat.state === 'open')),function(chat,i){return _c('v-list-item',{key:`c-${chat.ticket}-${i}`,staticClass:"px-0",attrs:{"id":`c-${chat.ticket}-${i}`}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(chat.name)+" ")])],1),_c('v-list-item-action',[_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.agentMenu),function(menu,i){return _c('div',{key:`m-${chat.ticket}-${i}`},[_c('v-list-item',{attrs:{"link":""},on:{"click":menu.action}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(menu.name)+" ")])],1),(
                              _vm.agentMenu.length > 1 && i < _vm.agentMenu.length - 1
                            )?_c('v-divider'):_vm._e()],1)}),0)],1)],1)],1)}),1)],1)]}}],null,true)})],1),(_vm.pageCount && _vm.pageCount > 0)?_c('v-pagination',{staticClass:"mt-auto",attrs:{"color":Boolean(_vm.banner) ? _vm.banner['page_bg_color'] : 'primary',"length":_vm.pageCount && _vm.pageCount > 0 ? _vm.pageCount : 0},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}):_c('div',{staticClass:"mt-auto"})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }