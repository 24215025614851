var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-data-table',{attrs:{"no-results-text":"Não há registro.","no-data-text":"Não há registro.","loading-text":"Carregando atendimentos...","loading":_vm.loadingQueue,"items-per-page":_vm.itemsPerPage,"hide-default-footer":"","disable-sort":"","page":_vm.page,"items":_vm.supervisorQueue,"item-key":"create_date","headers":[
      {
        text: 'Ticket',
        value: 'ticket',
        sortable: false
      },
      {
        text: 'Status',
        value: 'state',
        sortable: false
      },
      {
        text: 'Solicitante',
        value: 'name',
        sortable: false
      },
      {
        text: 'Duração',
        value: 'service_time',
        sortable: false
      },
      {
        text: 'Agente',
        value: 'agent',
        sortable: false
      },
      {
        text: '',
        value: 'actions',
        sortable: false
      },
    ]},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:`item.state`,fn:function({ item }){return [_c('td',{directives:[{name:"show",rawName:"v-show",value:(item),expression:"item"}]},[_c('v-chip',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.setQueueStatus(item.state))+" ")])],1)]}},{key:`item.name`,fn:function({ item }){return [_c('td',{directives:[{name:"show",rawName:"v-show",value:(item),expression:"item"}]},[_vm._v(" "+_vm._s(_vm.setMobileMask(item.name))+" ")])]}},{key:`item.service_time`,fn:function({ item, index }){return [_c('td',{directives:[{name:"show",rawName:"v-show",value:(item),expression:"item"}]},[_vm._v(" "+_vm._s(item.agent ? _vm.diffs[index] : '')+" ")])]}},{key:`item.agent`,fn:function({ item }){return [_c('td',{directives:[{name:"show",rawName:"v-show",value:(item),expression:"item"}]},[_vm._v(" "+_vm._s(item.agent.name ? item.agent.name.toString() : '')+" ")])]}},{key:`item.actions`,fn:function({ item }){return [_c('td',{staticClass:"text-end"},[_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({key:item.ticket,attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.supervisorMenu),function(menu,i){return _c('div',{key:i},[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.setWsAction(menu.action, _vm.userTeamId, item.id, item.team.id)}}},[_c('v-list-item-title',[_vm._v(_vm._s(menu.name))])],1),(_vm.supervisorMenu.length > 1 && i < _vm.supervisorMenu.length -1)?_c('v-divider'):_vm._e()],1)}),0)],1)],1)]}}],null,true)}),_c('v-dialog',{attrs:{"width":"350"},model:{value:(_vm.showAgentDialog),callback:function ($$v) {_vm.showAgentDialog=$$v},expression:"showAgentDialog"}},[(_vm.currentTicketTeamId)?_c('v-list',{attrs:{"dense":""}},[_c('v-list-item-group',_vm._l((_vm.currentTicketTeamId
            ? _vm.agents.filter(item => item.agent.team.id == _vm.currentTicketTeamId)
            : []),function(el,i){return _c('v-list-item',{key:i},[_c('v-list-item-content',{domProps:{"textContent":_vm._s(el.agent.name)},on:{"click":function($event){return _vm.assignServiceToAgent(
              el.agent.id,
              _vm.currentTicketId,
              el.agent.team.id
            )}}})],1)}),1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }