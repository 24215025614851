var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticStyle:{"overflow":"hidden"},attrs:{"rounded":"xl"}},[_c('v-layout',{style:(`height: calc(${_vm.cardHeight}vh - 125px); min-height: calc(${_vm.cardHeight}vh - ${_vm.diffHeight}px);`)},[_c('v-card',{attrs:{"elevation":"0","width":"100%","rounded":"md"}},[_c('v-card-title',{staticClass:"pl-0 py-0 d-flex justify-space-between rounded-0",style:(`width: 100%;
                      height: 40px;
                      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
                      background-color: ${
                        Boolean(_vm.banner) ? _vm.banner['page_bg_color'] : 'white'
                      }
                `)},[_c('span',{staticClass:"pl-4 body-1",style:(`color: ${
            Boolean(_vm.banner) ? _vm.banner['page_text_color'] : '#1d1d1d'
          }`)},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('div',[_c('v-menu',{attrs:{"offset-y":"","rounded":"lg"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(_vm.hasFilter)?_c('v-btn',_vm._g(_vm._b({staticClass:"text-capitalize",attrs:{"plain":""}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-1",attrs:{"color":Boolean(_vm.banner) ? _vm.banner['page_text_color'] : '#1d1d1d'}},[_vm._v(" filter_list ")]),_vm._v(" "+_vm._s(_vm.filterTitle)+" ")],1):_vm._e()]}}])},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.filters),function(item,index){return _c('div',{key:index},[_c('v-list-item',{staticClass:"py-0",attrs:{"link":""},on:{"click":function($event){return _vm.setTeam(item)}}},[_c('v-list-item-title',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(item.name)+" ")])],1),(_vm.filters.length > 1 && index < _vm.filters.length - 1)?_c('v-divider'):_vm._e()],1)}),0)],1),_c('v-menu',{attrs:{"offset-y":"","rounded":"lg"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(_vm.hasOptions)?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":Boolean(_vm.banner) ? _vm.banner['page_text_color'] : '#1d1d1d'}},[_vm._v(" more_vert ")])],1):_vm._e()]}}])},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.options),function(item,index){return _c('div',{key:index},[_c('v-list-item',{staticClass:"py-0",attrs:{"link":""}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(" "+_vm._s(item.icon)+" ")])],1),_c('v-list-item-title',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(item.title)+" ")])],1),(_vm.options.length > 1 && index < _vm.options.length - 1)?_c('v-divider'):_vm._e()],1)}),0)],1)],1)]),_c('v-card-text',{staticClass:"px-0"},[_vm._t("default")],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }