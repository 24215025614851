<template>
  <v-list color="#e5e5e5">
    <!-- v-for="objMessage in agentQueue" :key="objMessage.id" -->
    <div color="white">
     <!--  <v-layout justify-center py-2>
        <v-card
          height="24"
          width="100"
          class="d-flex justify-center d-flex align-center"
          shaped
        >
          <span v-text="agentQueue[0].id" :style="styleSpan" />
        </v-card>
      </v-layout> -->
      <!-- :id="index == currentChat.length - 1 ? 'lastMessage' : ''" -->
      <v-list-item
        v-for="(item, index) in currentChat"
        :key="index"
        :ref="
          objSearchMessage && item.id === objSearchMessage.id
            ? 'scrollToMe'
            : ''
        "
        :class="
          objSearchMessage && item.id === objSearchMessage.id
            ? 'scrollToMe'
            : ''
        "
      >
        <v-layout
          v-if="item"
          :justify-start="item.isExternal"
          :justify-end="!item.isExternal"
          py-1
          @dblclick="emitReplyMessage(item)"
        >
        <!-- :color="item.sender_user_id[0] != userId ? 'white' : '#d9fdd3'" -->
          <v-card
            max-width="500px"
            min-width="80px"
            class="px-1 py-1 position-relative"
            :style="radius7"
            :color="item.isExternal ? 'white' : '#d9fdd3'"
            @mousemove="(menuId = item.id), (showMenu = false)"
            @mouseleave="mouseLeave()"
          >
            <div style="cardBox" class="position-absolute top-right">
              <!-- <span style="whiteSpacePre" v-text="' '" /> -->
              <v-menu
                v-if="menuId === item.id || (menuId === item.id && showMenu)"
                v-model="showMenu"
                
                style="mw600"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    color="rgba(0,0,0, 0)"
                    height="20"
                    left
                    elevation="0"
                    x-small
                  >
                    <v-icon> expand_more </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(menu, index) in item.type === 'message'
                      ? menuItems
                      : menuAttachItems"
                    :key="index"
                    @click="clickMenu(menu, item)"
                  >
                    <v-list-item-title style="listTitle">
                      {{ menu.title }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
            <div class="d-flex flex-column-reverse">
              <div class="d-flex  justify-space-between  align-end ">
                <span>{{ item.content }} </span>
                <span class="message_time">{{ formatTimeToScreen(item.message_time) }}</span>
              </div>
              <div
                v-if="item.quoted_message"
                elevation="0"
                :style="replyMessageBox(item)"
                class="pl-3 mousePointer"
                @click="goToReplyMessage(item)"
              >
                <span class="font-weight-bold" :style="spanReplyMessage(item)">
                  {{
                    item.quoted_message.isExternal
                      ? "Cliente"
                      : "Você"
                  }}
                </span>
                <br />
                <span style="replyMessageId" class="d-inline-block text-truncate">
                  {{ item.quoted_message.content }}
                </span>
              </div>
            </div>


            <!-- <div>
              <v-layout
                v-if="item.type != 'message'"
                justify-center
                align-center
                :pt-6="item.type != 'message'"
                @click="clickMessage(item)"
              >
                <v-img
                  v-if="isImage(item)"
                  :lazy-src="setSrcImage(item)"
                  :src="setSrcImage(item)"
                  max-height="350px"
                  max-width="300px"
                />
                <div
                  v-else
                  class="d-flex justify-center align-center"
                  style="notImage"
                >
                  <v-icon color="white">
                    {{ fileIcons(item) }}
                  </v-icon>
                </div>
                <span
                  v-if="!isImage(item)"
                  class="px-3 text-truncate"
                  v-text="item.message"
                  :style="spanMessage"
                />
                <v-btn
                  v-if="!isImage(item)"
                  outlined
                  fab
                  x-small
                  color="grey"
                  height="27"
                  width="27"
                  :loading="isDownload.includes(item.id)"
                >
                  <v-icon color="grey"> arrow_downward </v-icon>
                </v-btn>
              </v-layout>
              <span
                v-if="item.type === 'message'"
                class="text-left"
                v-text="item.content"
                style="spanMessage"
              />
              <span class="px-6" />
              <v-layout style="mt16Minus" align-center justify-end>
                <span
                  v-text="formatTimeToScreen(item.create_date)"
                  :style="styleSpan"
                  class="pl-3 pr-1"
                />
                <v-icon
                  v-if="item.sender_user_id[0] === userId"
                  :v-text="messageIcon(item)"
                  size="14"
                  :color="messageIconColor(item)"
                />
              </v-layout>
            </div> -->
          </v-card>
        </v-layout>
      </v-list-item>
    </div>
  </v-list>
</template>

<script>
import moment from "moment";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Messages",

  props: {
    objSearchMessage: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      menuId: -1,
      showMenu: false,
      isDownload: [],
      menuItems: [{ id: 1, title: "Responder" }],
      menuAttachItems: [
        { id: 1, title: "Responder" }
      ],
      scrollToMessage: false,
    };
  },

  mounted() {
    setInterval(() => {
      this.scrollToElement();
    }, 300);
  },

  methods: {
    ...mapActions({
      downloadAttachment: "Attachment/downloadAttachment",
    }),

    scrollToElement() {
      const element = this.$refs.scrollToMe;
      if (element && element.length > 0 && !this.scrollToMessage) {
        this.scrollToMessage = true;
        element[0].$el.scrollIntoView({ behavior: "smooth" });
        setTimeout(() => {
          this.$emit('goToMessage', null)
        }, 600);
        
      }
    },

    async clickMenu(menu, message) {
      switch (menu.id) {
        case 1:
          this.emitReplyMessage(message);
          break;

        case 2:
          await this.download(message);
          break;

        default:
          break;
      }
    },

    emitReplyMessage(message) {
      this.$emit("replyMessage", message)
    },

    async clickMessage(item) {
      switch (item.type) {
        case "image":
          this.$emit("openImage", this.setSrcImage(item));
          break;

        case "attachment":
          await this.download(item);
          break;

        default:
          return;
      }
    },

    async download(item) {
      const index = this.isDownload.findIndex((el) => el === item.id);
      if (index < 0) {
        this.isDownload.push(item.id);
        await this.downloadAttachment({
          token: this.userToken,
          id: item.attachment_id[0],
        });
        index ? this.isDownload.splice(index, 1) : false;
      }
    },

    isImage(item) {
      return item.type.includes("image") ? true : false;
    },

    fileIcons(item) {
      if (item.content.includes(".pdf")) return "picture_as_pdf";
      else if (item.content.includes(".xls")) return "grid_view";
      else if (
        item.content.includes(".txt", ".doc", ".odt", ".rtf", ".tex", ".wpd")
      )
        return "description";
      else return "attach_file";
    },

    setSrcImage(item) {
      return item.attachment_id
        ? String(
            process.env.VUE_APP_BASE_URL + "/web/image/" + item.attachment_id[0]
          )
        : "";
    },

    mouseLeave() {
      if (this.showMenu == false) {
        this.menuId = -1;
      }
    },

    formatTimeToScreen(date) {
      let newDate = new Date(date);
      let formatedDate = new Date(
        newDate.valueOf() - newDate.getTimezoneOffset() * 60000
      );
      formatedDate = moment(formatedDate).locale("pt-br").format("HH:mm");
      return formatedDate;
    },

    formatDateToScreen(date) {
      let newDate = new Date(date);
      let formatedDate = new Date(
        newDate.valueOf() - newDate.getTimezoneOffset() * 60000
      );
      formatedDate = moment(formatedDate).locale("pt-br").format("DD/MM/YYYY");
      return formatedDate;
    },

    messageIcon(item) {
      if (typeof item.id === "number") {
        return "done_all";
        // switch (item.received) {
        //   case true:
        //   case false:
        //     return "done";
        // }
      } else {
        return "schedule";
      }
    },

    messageIconColor(item) {
      return "blue";
      // if (item.visualized) {
      // } else {
      //   return "";
      // }
    },

    replyMessageBox(item) {
      return `width: 100%; border-radius: 4px;border-left: 4px solid ${
        item.isExternal ? "#53bdeb" : "#06cf9c"
      };background-color: ${
        item.isExternal ? "#f5f6f6" : "#d1f4cc"
      }`;
    },

    spanReplyMessage(item) {
      return `font-family: 'Poppins', sans-serif;font-size: 10.0px;color: ${
        item.isExternal ? "#53bdeb" : "#06cf9c"
      }`;
    },
    goToReplyMessage(item){
      this.$emit('goToMessage', item.quoted_message)
    }
  },

  computed: {
    ...mapGetters({
      usersList: "User/getUsersList",
      userToken: "Login/getToken",
      userAvatar: "User/getUserImage",
      userId: "User/getUserId",
      messagesList: "Chat/getMessagesList",
      queueSelected: "Ws/getQueueSelected",
      agents: "Ws/getAgents",
      userAgentId: "User/getUserAgentId",
      currentChat: "Ws/getCurrentChat"
    }),
    styleSpan() {
      return "font-family: 'Poppins', sans-serif; font-size: 12px; color:grey";
    },

    radius7() {
      return "border-radius: 7px";
    },

    cardBox() {
      return "margin-bottom: -24px; z-index: 0";
    },

    whiteSpacePre() {
      return "white-space: pre";
    },

    mw600() {
      return "max-width: 600px;";
    },

    listTitle() {
      return "font-family: 'Poppins', sans-serif;font-size: 12.0px;color:#111B21";
    },

    replyMessageId() {
      return "font-family: 'Poppins', sans-serif; font-size: 11.0px; color:#111B21; max-width: 100px;";
    },

    notImage() {
      return "width: 32px;\
              height: 32px;\
              border-radius: 5px;\
              background-color: rgb(66, 102, 245, 0.8);";
    },

    spanMessage() {
      return "font-family: 'Poppins', sans-serif;font-size: 13px;color:#111B21;";
    },

    mt16Minus() {
      return "margin-top: -16px";
    }    
  },

  watch: {
    showMenu: function () {
      if (!this.showMenu) this.menuId = -1;
    },

    objSearchMessage: function () {
      this.scrollToMessage = false;
    },
  },
};
</script>
<style scoped>

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.top-right {
  top: 0px;
  right: 2px;
}
.message_time {
  font-size: 10px;
  margin-left: 5px;
}

.mousePointer:hover {
  cursor: pointer;
}
.scrollToMe {
  animation-name: my-animation;
  animation-duration: 1s;
  animation-direction: alternate;
  animation-iteration-count: 4;
  animation-timing-function: linear;
  background-color: #9fa0a1;
  border-radius: 7px;
}
@keyframes my-animation {
  from {
    background-color: #e5e5e5;
  }
  to {
    background-color: #9fa0a1;
  }
}
</style>
