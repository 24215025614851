/* Module1.store.js */

import axios from "axios";
import iliosState from "@/ilios.store";
import moment from "moment";
import store from "../../store";

const initialState = () => ({
  apiState: iliosState.INIT,
  messagesList: [],
  lastUsers: [],
  searchUsers: [],
  responseStatus: null,
  messageError: [],
});

// State object
const state = initialState();

// Getter functions
const getters = {
  getMessagesList() {
    return state.messagesList;
  },

  getLastUsers() {
    return state.lastUsers;
  },

  getSearchUsers() {
    return state.searchUsers;
  },

  getResponseStatus() {
    return state.responseStatus;
  },

  getMessageError() {
    return state.messageError;
  },

  getApiState() {
    return state.apiState;
  },
};

// Actions
const actions = {
  async getMessagesIds(context, { token, domain }) {
    var result = null;
    context.commit("SET_API_STATE", {
      apiState: iliosState.LOADING,
    });
    await axios
      .get(process.env.VUE_APP_BASE_URL + `/iliosrest/get/chat.message`, {
        params: {
          fields: "['id']",
          domain: domain,
          order: "create_date ASC",
          context: {
            lang: "pt_BR",
            tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
        },
        headers: {
          "Content-Type": "form/x-www-form-urlencoded",
          charset: "utf-8",
          "Access-Token": token,
        },
      })
      .then((response) => {
        if (response["status"] == 200) {
          result = response.data.data.map((el) => el.id);
          context.dispatch("getMessages", {
            token: token,
            domain: `[('id', 'in', [${result.slice(
              result.length - 40,
              result.length
            )}])]`,
          });
        }
      })
      .catch((e) => {
        context.commit("SET_API_STATE", {
          apiState: iliosState.LOADED,
        });
        const messageError = e.response ? e.response.data.message : e;
        store.commit("Error/SET_MESSAGE_ERROR", {
          message: messageError,
        });
      });
    return result;
  },

  async getMessages(context, { token, domain, reverse = false }) {
    var result = null;
    context.commit("SET_API_STATE", {
      apiState: iliosState.LOADING,
    });
    await axios
      .get(process.env.VUE_APP_BASE_URL + `/iliosrest/get/chat.message`, {
        params: {
          fields:
            "['message', 'sender_user_id', 'receiver_user_id', 'received', 'create_date', 'visualized', 'reply_message_id', 'reply_msg_user', 'type', 'attachment_id']",
          domain: domain,
          order: `create_date ${reverse ? "ASC" : "DESC"}`,
          limit: 40,
          context: {
            lang: "pt_BR",
            tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
        },
        headers: {
          "Content-Type": "form/x-www-form-urlencoded",
          charset: "utf-8",
          "Access-Token": token,
        },
      })
      .then((response) => {
        if (response["status"] == 200) {
          result = response.data.data;
          context.commit("SET_MESSAGES_LIST", {
            messagesList: !reverse
              ? response.data.data.reverse()
              : response.data.data,
            reverse: reverse,
          });
        }
      })
      .catch((e) => {
        context.commit("SET_API_STATE", {
          apiState: iliosState.LOADED,
        });
        const messageError = e.response ? e.response.data.message : e;
        store.commit("Error/SET_MESSAGE_ERROR", {
          message: messageError,
        });
      });
    return result;
  },

  async getSimpleMessages(context, { token, domain }) {
    var result = null;
    context.commit("SET_API_STATE", {
      apiState: iliosState.LOADING,
    });
    await axios
      .get(process.env.VUE_APP_BASE_URL + `/iliosrest/get/chat.message`, {
        params: {
          fields:
            "['message', 'sender_user_id', 'receiver_user_id', 'create_date', 'type']",
          domain: domain,
          order: "create_date DESC",
          limit: "20",
          context: {
            lang: "pt_BR",
            tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
        },
        headers: {
          "Content-Type": "form/x-www-form-urlencoded",
          charset: "utf-8",
          "Access-Token": token,
        },
      })
      .then((response) => {
        if (response["status"] == 200) {
          result = response.data.data;
          context.commit("SET_API_STATE", {
            apiState: iliosState.LOADED,
          });
        }
      })
      .catch((e) => {
        context.commit("SET_API_STATE", {
          apiState: iliosState.LOADED,
        });
        const messageError = e.response ? e.response.data.message : e;
        store.commit("Error/SET_MESSAGE_ERROR", {
          message: messageError,
        });
      });
    return result;
  },

  async getSearchMessages(context, { token, domain, order }) {
    var result = null;
    context.commit("SET_API_STATE", {
      apiState: iliosState.LOADING,
    });
    await axios
      .get(process.env.VUE_APP_BASE_URL + `/iliosrest/get/chat.message`, {
        params: {
          fields:
            "['message', 'sender_user_id', 'receiver_user_id', 'received', 'create_date', 'visualized', 'reply_message_id', 'reply_msg_user', 'type', 'attachment_id']",
          domain: domain,
          order: `create_date ${order}`,
          limit: "40",
          context: {
            lang: "pt_BR",
            tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
        },
        headers: {
          "Content-Type": "form/x-www-form-urlencoded",
          charset: "utf-8",
          "Access-Token": token,
        },
      })
      .then((response) => {
        if (response["status"] == 200) {
          result = response.data.data;
          context.commit("SET_API_STATE", {
            apiState: iliosState.LOADED,
          });
        }
      })
      .catch((e) => {
        context.commit("SET_API_STATE", {
          apiState: iliosState.LOADED,
        });
        const messageError = e.response ? e.response.data.message : e;
        store.commit("Error/SET_MESSAGE_ERROR", {
          message: messageError,
        });
      });
    return result;
  },

  async getLastMessages(context, { token }) {
    var result = null;
    context.commit("SET_API_STATE", {
      apiState: iliosState.LOADING,
    });
    await axios
      .get(process.env.VUE_APP_BASE_URL + "/get/chat/last_messages", {
        params: {
          db: process.env.VUE_APP_DATA_BASE,
          context: {
            lang: "pt_BR",
            tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
        },
        headers: {
          "Content-Type": "form/x-www-form-urlencoded",
          charset: "utf-8",
          "Access-Token": token,
        },
      })
      .then((response) => {
        if (response["status"] == 200) {
          result = response.data;
          context.commit("SET_API_STATE", {
            apiState: iliosState.LOADED,
          });
        }
      })
      .catch((e) => {
        context.commit("SET_API_STATE", {
          apiState: iliosState.LOADED,
        });
        const messageError = e.response ? e.response.data.message : e;
        store.commit("Error/SET_MESSAGE_ERROR", {
          message: messageError,
        });
      });
    return result;
  },

  async cleanMessagesList(context) {
    await context.commit("CLEAN_MESSAGES_LIST");
  },

  reset(context) {
    context.commit("RESET");
  },
};

// Mutations
const mutations = {
  SET_MESSAGES_LIST(state, { messagesList, reverse = false }) {
    if (messagesList.length > 0) {
      let messageExist = false;
      state.messagesList.forEach((el) => {
        messageExist = el.messages.find((el) => el.id === messagesList[0].id);
        if (messageExist) {
          return;
        }
      });

      if (!messageExist) {
        function formatDateToScreen(date) {
          let newDate = new Date(date);
          let formatedDate = new Date(
            newDate.valueOf() - newDate.getTimezoneOffset() * 60000
          );
          formatedDate = moment(formatedDate)
            .locale("pt-br")
            .format("DD/MM/YYYY");
          return formatedDate;
        }

        let objMessages = [];
        messagesList.forEach((message) => {
          let formatDate = formatDateToScreen(message.create_date);
          let findObjMessages = objMessages.find((el) => el.id === formatDate);
          if (findObjMessages) {
            !reverse
              ? findObjMessages.messages.push(message)
              : findObjMessages.messages.unshift(message);
          } else {
            objMessages.unshift({ id: formatDate, messages: [message] });
          }
        });

        objMessages = reverse ? objMessages.reverse() : objMessages;
        objMessages.forEach((el) => {
          let findMessage = state.messagesList.find((msg) => msg.id === el.id);
          if (findMessage) {
            for (let i = el.messages.length - 1; i >= 0; i--) {
              reverse
                ? findMessage.messages.push(el.messages[i])
                : findMessage.messages.unshift(el.messages[i]);
            }
          } else {
            el.messages = reverse ? el.messages.reverse() : el.messages;
            reverse
              ? state.messagesList.push(el)
              : state.messagesList.unshift(el);
          }
        });
      }
    }
    state.apiState = iliosState.LOADED;
  },

  ADD_NEW_MESSAGE(state, { objMessage }) {
    let currentDate = moment(new Date()).locale("pt-br").format("DD/MM/YYYY");
    let findObjMsg = state.messagesList.find((el) => el.id === currentDate);
    let newMsg = {
      id: objMessage.id,
      message: objMessage.message,
      sender_user_id: objMessage.sender_user_id,
      receiver_user_id: objMessage.receiver_user_id,
      received: objMessage.received ? objMessage.received : false,
      reply_message_id: objMessage.reply_message_id,
      reply_msg_user: objMessage.reply_msg_user,
      visualized: false,
      create_date: objMessage.create_date,
      type: objMessage.type,
      attachment_id: objMessage.attachment_id,
    };
    if (findObjMsg) {
      findObjMsg.messages.push(newMsg);
    } else {
      state.messagesList.push({
        id: currentDate,
        messages: [newMsg],
      });
    }
  },

  SET_MESSAGE_SUCCESSFULLY(state, { objMessage }) {
    let findObjMsg = state.messagesList.find(
      (el) => el.id === objMessage.message_date
    );
    if (findObjMsg) {
      let findMessage = findObjMsg.messages.find(
        (el) => el.id === objMessage.fe_message_id
      );
      if (findMessage) {
        findMessage.id = objMessage.chat_message_id;
        findMessage.attachment_id = objMessage.attachment_id
          ? objMessage.attachment_id
          : false;
      }
    }
  },

  SET_MESSAGE_RECEIVED(state, { objMessage }) {
    let findObjMsg = state.messagesList.find(
      (el) => el.id === objMessage.message_date
    );
    if (findObjMsg) {
      let findMessage = findObjMsg.messages.find(
        (el) => el.id === objMessage.chat_message_id
      );
      if (findMessage) {
        findMessage.received = true;
      }
    }
  },

  SET_LAST_MESSAGES(state, { lastMessages }) {
    let usersList = store.getters["User/getActiveUsersList"];
    if (lastMessages) {
      usersList.forEach((objUser) => {
        let findMessage = lastMessages.find((el) => {
          if (el.sender_user_id != Number(localStorage.getItem("userId"))) {
            return el.sender_user_id === objUser.id;
          } else {
            return el.receiver_user_id === objUser.id;
          }
        });
        if (findMessage) {
          findMessage.create_date = new Date(findMessage.create_date).getTime();
          state.lastUsers.unshift({
            id: objUser.id,
            avatar: objUser.avatar,
            name: objUser.name,
            lastMessage: findMessage,
          });
        }
      });
    }
  },

  SET_NEW_LAST_MESSAGE(state, { lastMessage, objUser, isObjLastUser }) {
    let user = isObjLastUser
      ? objUser
      : state.lastUsers.find((user) => user.id === objUser.id);
    if (user) {
      if (lastMessage.message) {
        user["lastMessage"] = lastMessage;
      }
    } else {
      objUser["lastMessage"] = lastMessage;
      state.lastUsers.unshift({
        id: objUser.id,
        avatar: objUser.avatar,
        name: objUser.name,
        lastMessage: objUser.lastMessage,
        type: lastMessage.type,
      });
    }
  },

  SET_SEARCH_USERS(state, { userName }) {
    if (userName) {
      let usersList = store.getters["User/getActiveUsersList"];
      state.searchUsers = state.lastUsers.filter((user) =>
        user.name.toLowerCase().includes(userName.toLowerCase())
      );
      usersList.forEach((user) => {
        if (
          user.name.toLowerCase().includes(userName.toLowerCase()) &&
          !state.searchUsers.find((el) => el.id === user.id)
        ) {
          state.searchUsers.push(user);
        }
      });
    } else {
      state.searchUsers = [];
    }
  },

  SET_MESSAGE_VIEW(state, { userChat }) {
    if (state.messagesList.length > 0) {
      state.messagesList.forEach((objMessage) => {
        let messages = objMessage.messages.filter(
          (el) => el.receiver_user_id[0] === userChat && el.visualized === false
        );
        if (messages) {
          messages.forEach((el) => {
            el.visualized = true;
          });
        }
      });
    }
  },

  SET_MESSAGE_ERROR(state, { message }) {
    state.messageError.push(String(message));
  },

  SET_API_STATE(state, { apiState }) {
    state.apiState = apiState;
  },

  CLEAN_MESSAGES_LIST(state) {
    state.messagesList = [];
  },

  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },
};

export default {
  actions,
  state,
  getters,
  mutations,
  namespaced: true,
};
