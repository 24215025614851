<template>
  <div>
    <v-data-table
      no-results-text="Não há registro."
      no-data-text="Não há registro."
      loading-text="Carregando atendimentos..."
      :loading="loadingQueue"
      :items-per-page="itemsPerPage"
      hide-default-footer
      disable-sort
      :page.sync="page"
      :items="supervisorQueue"
      item-key="create_date"
      :headers="[
        {
          text: 'Ticket',
          value: 'ticket',
          sortable: false
        },
        {
          text: 'Status',
          value: 'state',
          sortable: false
        },
        {
          text: 'Solicitante',
          value: 'name',
          sortable: false
        },
        {
          text: 'Duração',
          value: 'service_time',
          sortable: false
        },
        {
          text: 'Agente',
          value: 'agent',
          sortable: false
        },
        {
          text: '',
          value: 'actions',
          sortable: false
        },
      ]"
    >
      <template v-slot:[`item.state`]="{ item }">
        <td v-show="item">
          <v-chip
            small
          >
            {{ setQueueStatus(item.state) }}
          </v-chip>
        </td>
      </template>
      <template v-slot:[`item.name`]="{ item }">
        <td v-show="item">
          {{ setMobileMask(item.name) }}
        </td>
      </template>
      <template v-slot:[`item.service_time`]="{ item, index }">
        <td v-show="item">
          {{ item.agent ? diffs[index] : ''}}
        </td>
      </template>
      <template v-slot:[`item.agent`]="{ item }">
        <td v-show="item">
          {{ item.agent.name ? item.agent.name.toString() : ''}}
        </td>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <td class="text-end">
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on" :key="item.ticket">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list dense>
              <div
                v-for="(menu, i) in supervisorMenu"
                :key="i"
              >
                <v-list-item
                  @click="setWsAction(menu.action, userTeamId, item.id, item.team.id)"
                  link
                >
                  <v-list-item-title>{{ menu.name }}</v-list-item-title>
                </v-list-item>
                <v-divider v-if="supervisorMenu.length > 1 && i < supervisorMenu.length -1" />
              </div>
            </v-list>
          </v-menu>
        </td>
      </template>
    </v-data-table>

    <v-dialog
      v-model="showAgentDialog"
      width="350"
    >
      <v-list
        dense
        v-if="currentTicketTeamId"
      >
        <v-list-item-group>
          <v-list-item
            v-for="(el, i) in currentTicketTeamId
              ? agents.filter(item => item.agent.team.id == currentTicketTeamId)
              : []"
            :key="i"
          >
            <v-list-item-content
              @click="assignServiceToAgent(
                el.agent.id,
                currentTicketId,
                el.agent.team.id
              )"
              v-text="el.agent.name"
            />
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import moment from "moment";
export default {
  name: "SupervisorQueue",
  props: {
    page: {
      type: Number,
      default: 1
    },
    itemsPerPage: {
      type: Number,
      default: 10
    },
  },
  data: () => ({
    showAgentDialog: false,
    currentTicketId: null,
    currentTicketTeamId: null,
    diffs: [],
    durationInterval: null
  }),
  methods: {
    ...mapMutations({
      SET_MESSAGE_ERROR: "Error/SET_MESSAGE_ERROR",
    }),
    setMobileMask(mobile) {
      if (mobile.length > 0) {
        let mobileValue = mobile.replace(/\D/g, '');

        if (mobileValue.length <= 6) {
          mobileValue = '(' + mobileValue.substring(0, 2) + ') ' + mobileValue.substring(2);
        } else {
          mobileValue = '(' + mobileValue.substring(0, 2) + ') ' + mobileValue.substring(2, 6) + '-' + mobileValue.substring(6, 10);
        }
        return mobileValue
      }
    },
    setQueueStatus(status) {
      switch (status) {
        case "draft":
          return "Provisório"
        case "open":
          return "Em Atendimento"
        case "close":
          return "Fechado"
      }
    },
    async setWsAction(action, teamId, chatId, ticketTeamId) {
      switch (action) {
        case 0:
          await this.takeChat(teamId, chatId);
          break
        case 1:
          this.selectAgent(chatId, ticketTeamId);
          break
      }
    },
    async takeChat(teamId, chatId) {
      /*
        Método que assume o atendimento que esta na fila de espera.

        params:
          teamId ( INT ) = id do time que o agente pertence.
          chatId ( INT ) = id do atendimento.
      */
      try {
        this.$socket.sendObj({
          db_name: process.env.VUE_APP_DATA_BASE,
          access_token: this.userToken,
          action: "new_agent_chat",
          body: {
            team_id: teamId,
            ticket_id: chatId,
          }
        });
      } catch (e) {
        const messageError = e.response ? e.response.data.message : e;
        store.commit("Error/SET_MESSAGE_ERROR", {
          message: messageError,
        });
      }
    },
    async assignServiceToAgent(agentId, ticketId, teamId) {
      /*
        Método que transfere o atendimento para um agente.

        params:
          agentId  ( INT ) = id do agente que receberá o ticket.
          teamId   ( INT ) = id do time que o agente pertence.
          ticketId ( INT ) = id do atendimento.
      */

      try {
        await this.$socket.sendObj({
          db_name: process.env.VUE_APP_DATA_BASE,
          access_token: this.userToken,
          action: "assign_service_to_agent",
          body: {
            agent_id: agentId,
            ticket_id: ticketId,
            team_id: teamId,
          }
        });

        this.currentTicketId = null
        this.currentTicketTeamId = null
        this.showAgentDialog = false
        await this.setAllAgents()
        await this.setQueue()
      } catch (e) {
        const messageError = e.response ? e.response.data.message : e;
        this.SET_MESSAGE_ERROR({
          message: messageError,
        });
      }
    },
    selectAgent(ticketId, ticketTeamId) {
      this.currentTicketId = ticketId
      this.currentTicketTeamId = ticketTeamId
      this.showAgentDialog = true
    },
    async setAllAgents() {
      try {
        await this.$socket.sendObj({
          db_name: process.env.VUE_APP_DATA_BASE,
          access_token: this.userToken,
          action: "get_all_agents",
          body: {
            team_ids: this.teams.map(team => team.id)
          }
        });
      } catch (e) {
        const messageError = e.response ? e.response.data.message : e;
        this.SET_MESSAGE_ERROR({
          message: messageError,
        });
      }
    },
    async setQueue() {
      try {
        await this.$socket.sendObj({
          db_name: process.env.VUE_APP_DATA_BASE,
          access_token: this.userToken,
          action: "get_all_tickets_in_queue",
          body: {
            team_ids: this.teams.map(team => team.id)
          }
        });
      } catch (e) {
        const messageError = e.response ? e.response.data.message : e;
        this.SET_MESSAGE_ERROR({
          message: messageError,
        });
      }
    },
    getDuration(){
      let diffs = []
      this.supervisorQueue.forEach(element => {
        let newDate = new Date(element.duration);
        let formatedDate = new Date(
          newDate.valueOf() - newDate.getTimezoneOffset() * 60000
        );
        let diff = moment().diff(moment(formatedDate));
        let hours = Math.floor(diff / 3600000);
        diff -= hours * 3600000;
        let minutes = Math.floor(diff / 60000);
        diff -= minutes * 60000;
        let seconds = Math.floor(diff / 1000);

        let formattedDiff = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
        diffs.push(formattedDiff)
      });
      this.diffs = diffs
    }
  },
  computed: {
    ...mapGetters({
      loadingQueue: "Management/getLoadingQueue",
      queue: "Ws/getQueue",
      queueMenu: "Management/getQueueMenu",
      supervisorMenu: "Management/getSupervisorMenu",
      currentQueue: "Ws/getCurrentQueue",
      banner: "Panel/getBanner",
      userTeamId: "User/getUserTeamId",
      userToken: "Login/getToken",
      agents: "Ws/getAgents",
      teams: "User/getTeams"
    }),
    supervisorQueue() {
      /*
        Propriedade computada que retorna fila de atendimentos do supervisor.
        Apresentando apenas atendimentos em 'draft'.

        draft = Provisório
      */
      if (Boolean(this.queue[this.currentQueue] && this.queue[this.currentQueue].team.tickets.length > 0)) {
        return this.queue[this.currentQueue].team.tickets.filter(ticket => ticket.state != "close")
      }
      else {
        return []
      }
    },
  },
  created() {
    this.durationInterval = setInterval(this.getDuration, 500);
  },
  beforeDestroy(){
    clearInterval(this.durationInterval)
  }
};
</script>