<template>
  <ApplicantCard
    :title="'Fila de espera'"
    :hasOptions="false"
    :hasFilter="isSupervisor"
    :options="[]"
    :filters="isSupervisor ? teams : []"
    :filterTitle="'Times'"
    :cardHeight="63"
    :diffHeight="62.5"
  >
    <v-card-text class="py-1 px-0">
      <SupervisorQueue v-if="isSupervisor" />
      <Queue v-else />
    </v-card-text>
  </ApplicantCard>
</template>
<script>
import SupervisorQueue from "@/components/Global/SupervisorQueue.vue"
import ApplicantCard from "../Global/ApplicantCard.vue";
import Queue from "@/components/Global/Queue.vue";
import { mapGetters } from "vuex";
export default {
  name: "MainComponent",
  components: {
    SupervisorQueue,
    ApplicantCard,
    Queue,
  },
  computed: {
    ...mapGetters({
      isSupervisor: "User/getIsSupervisor",
      teams: "User/getTeams"
    })
  }
};
</script>
