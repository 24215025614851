var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticStyle:{"overflow":"hidden"},attrs:{"rounded":"xl"}},[(_vm.agentChats.length > 0)?_c('div',{staticClass:"box",on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.cleanMenus()}}},[_c('SearchMessage',{ref:"compSearchMessage",on:{"clickMessage":_vm.setSearchMessage}}),_c('v-layout',{staticStyle:{"height":"calc(100vh - 85px)"}},[[_c('div',{staticClass:"d-flex applicantBox"},[_c('v-navigation-drawer',{attrs:{"permanent":"","floating":"","mini-variant":_vm.showApplicant,"width":"200px","color":Boolean(_vm.banner) ? _vm.banner['page_bg_color'] : 'white'}},[_c('v-list',{staticClass:"py-0",attrs:{"dense":""}},_vm._l((_vm.agentChats),function(chat,indexChat){return _c('v-list-item',{key:indexChat,staticClass:"px-0",attrs:{"link":""},on:{"click":function($event){return _vm.selectQueue(chat.id, _vm.userAgentId)}}},[_c('div',[_c('v-list-item-content',{staticClass:"pl-4 pt-0"},[_c('v-list-item-title',{staticClass:"text-caption",style:(`color: ${
                      Boolean(_vm.banner) ? _vm.banner['page_text_color'] : '#1d1d1d'
                    }`)},[_vm._v(" "+_vm._s(chat.name)+" ")]),_c('v-list-item-subtitle',[_c('v-chip',{attrs:{"x-small":""}},[_vm._v(" "+_vm._s(chat.ticket)+" ")])],1)],1),_c('v-divider')],1)])}),1)],1),(_vm.$vuetify.breakpoint.mdAndDown)?_c('v-btn',{staticClass:"my-auto showApplicantBtn",style:(`background-color: ${
              Boolean(_vm.banner) ? _vm.banner['page_bg_color'] : 'white'
            }`),attrs:{"icon":"","small":"","elevation":"1"},on:{"click":function($event){_vm.showApplicant = !_vm.showApplicant}}},[_c('v-icon',{attrs:{"color":Boolean(_vm.banner) ? _vm.banner['page_text_color'] : '#1d1d1d'}},[_vm._v(" "+_vm._s(!_vm.showApplicant ? "chevron_left" : "chevron_right")+" ")])],1):_vm._e()],1)],_c('v-layout',{attrs:{"column":""}},[_c('v-card',{staticClass:"rounded-0",attrs:{"elevation":"0","height":_vm.headerHeight,"color":Boolean(_vm.banner) ? _vm.banner['page_bg_color'] : 'white',"width":"100%"}},[_c('v-card-title',{staticClass:"py-0 d-flex justify-space-between",staticStyle:{"height":"40px","border-left":"1px solid rgba(0, 0, 0, 0.2)"}},[_c('div',{staticClass:"d-flex flex-row align-center"},[_c('div',{staticClass:"d-flex flex-column ml-1"},[_c('span',{staticClass:"text-caption",style:(`color: ${
                    Boolean(_vm.banner) ? _vm.banner['page_text_color'] : '#1d1d1d'
                  }`)},[_vm._v(" "+_vm._s(!_vm.showApplicant ? _vm.$vuetify.breakpoint.lgAndUp && _vm.agentChats.length > 0 && Boolean(_vm.agentChats[_vm.queueSelected]) && Boolean(_vm.currentChat !== null) ? _vm.agentChats[_vm.queueSelected].name : "" : _vm.agentChats.length > 0 && Boolean(_vm.agentChats[_vm.queueSelected]) && Boolean(_vm.currentChat !== null) ? _vm.agentChats[_vm.queueSelected].name : "")+" ")]),_c('v-chip',{attrs:{"x-small":""}},[_c('span',{staticStyle:{"font-size":"12px","font-weight":"400"}},[_vm._v(" "+_vm._s(_vm.agentChats[_vm.queueSelected].ticket)+" ")])])],1)]),(
                Boolean(_vm.currentChat !== null) &&
                _vm.agentChats.length > 0 &&
                Boolean(_vm.agentChats[_vm.queueSelected])
              )?_c('v-menu',{attrs:{"offset-y":"","rounded":"lg"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":Boolean(_vm.banner) ? _vm.banner['page_text_color'] : '#1d1d1d'}},[_vm._v(" more_vert ")])],1)]}}],null,false,2698397285)},[_c('v-list',{attrs:{"dense":""}},_vm._l(([
                    {
                      icon: 'done',
                      title: 'Encerrar atendimento',
                      action: () => _vm.closeTicket(),
                    },
                    // {
                    //   icon: 'trending_up',
                    //   title: 'Transferir atendimento',
                    // },
                    // {
                    //   icon: 'search',
                    //   title: 'Procurar',
                    // },
                  ]),function(item,index){return _c('div',{key:index},[_c('v-list-item',{staticClass:"py-0",attrs:{"link":""},on:{"click":item.action}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(" "+_vm._s(item.icon)+" ")])],1),_c('v-list-item-title',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(item.title)+" ")])],1)],1)}),0)],1):_vm._e()],1),_c('v-layout')],1),_c('v-card',{ref:"compMessages",staticClass:"d-flex align-end flex-column-reverse overflow-auto overflow-x-hidden rounded-0",attrs:{"elevation":"0","height":_vm.calcViewHeight,"color":"#e5e5e5","id":"compMessages"},on:{"scroll":_vm.currentHeight}},[_c('div',{style:(_vm.w100)},[_c('Messages',{attrs:{"objSearchMessage":_vm.objSearchMessage},on:{"replyMessage":_vm.setReplyMessage,"openImage":_vm.setImage,"goToMessage":function($event){return _vm.goToMessage($event)}}})],1),(_vm.chatHeight < -500)?_c('v-layout',{staticClass:"goToStart",attrs:{"pr-6":"","pb-6":""}},[_c('v-btn',{attrs:{"fab":"","color":Boolean(_vm.banner) ? _vm.banner['page_bg_color'] : 'primary',"small":"","elevation":"2"},on:{"click":function($event){return _vm.goToStartMessages()}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" arrow_downward ")])],1)],1):_vm._e(),(_vm.chatLoading)?_c('v-layout',{style:(_vm.w100),attrs:{"justify-center":"","align-center":""}},[_c('div',{staticClass:"d-flex justify-center align-center rounded-circle",staticStyle:{"height":"80px"}},[_c('v-progress-circular',{attrs:{"size":50,"color":"blue","indeterminate":""}})],1)]):_vm._e()],1),(_vm.showReplyMessage)?_c('v-card',{staticClass:"pl-10 d-flex align-center rounded-0",style:(`${_vm.w100}; padding: 8px 0 2px 0;`),attrs:{"elevation":"0","color":_vm.frontColor}},[(_vm.messageReply)?_c('v-card',{staticClass:"pl-3",style:(`width: 100%; border-radius: 4px;border-left: 4px solid ${
              _vm.messageReply.isExternal
                ? '#53bdeb'
                : '#06cf9c'
            };`),attrs:{"color":"#e5e7ea","elevation":"0","height":_vm.footerHeight}},[_c('span',{staticClass:"font-weight-bold",style:(`font-family: 'Poppins', sans-serif;font-size: 11.0px;color:${
                _vm.messageReply.isExternal
                  ? '#53bdeb'
                  : '#06cf9c'
              }`)},[_vm._v(" "+_vm._s(_vm.messageReply.isExternal ? "Cliente" : "Você")+" ")]),_c('br'),_c('p',{style:(`font-family: 'Poppins', sans-serif;font-size: 12.0px;color:#111B21;max-width: ${_vm.replyMaxWidth}px;`)},[_vm._v(" "+_vm._s(_vm.messageReply.content)+" ")])]):_vm._e(),_c('div',{staticClass:"pl-6"}),_c('v-btn',{attrs:{"icon":"","color":"grey","height":"32","width":"32"},on:{"click":function($event){return _vm.closeMessageReply()}}},[_c('v-icon',[_vm._v(" close ")])],1),_c('div',{staticClass:"pr-6"})],1):_vm._e(),_c('v-card',{staticClass:"px-6 d-flex align-center rounded-0",style:(_vm.w100),attrs:{"elevation":"0","height":_vm.footerHeight,"color":_vm.frontColor}},[_c('v-layout',{attrs:{"align-center":""}},[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],staticClass:"mr-3",attrs:{"text":"","icon":"","color":"#54656f","large":"","outlined":""},on:{"click":function($event){return _vm.openSelectFiles()}}},[_c('v-icon',[_vm._v(" attachment ")])],1),_c('v-text-field',{ref:"textFieldMessage",staticClass:"rounded-md",attrs:{"clearable":"","flat":"","dense":"","hide-details":"","outlined":"","color":"blue","background-color":"white","placeholder":_vm.showAttachmentList
                  ? 'Mensagem bloqueada'
                  : 'Digite sua mensagem',"height":"42px","disabled":_vm.showAttachmentList},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.prepareSendMessages()}},model:{value:(_vm.userMessage),callback:function ($$v) {_vm.userMessage=$$v},expression:"userMessage"}}),_c('v-btn',{staticClass:"ml-3",attrs:{"text":"","icon":"","color":"#54656f","large":""},on:{"click":function($event){return _vm.prepareSendMessages()}}},[_c('v-icon',[_vm._v("send")])],1)],1)],1)],1)],2),_c('ShowImage',{attrs:{"open":_vm.openImage,"image":_vm.srcImage},on:{"closeImage":function($event){_vm.openImage = false}}}),_c('AttachmentFile',{directives:[{name:"show",rawName:"v-show",value:(_vm.showAttachmentList),expression:"showAttachmentList"}],ref:"compAttachment",style:(`height: ${_vm.footerHeight}px; background-color: ${_vm.frontColor}`),attrs:{"model":'chat.message'},on:{"hasAttachments":function($event){(_vm.showAttachmentList = true), (_vm.userMessage = '')},"noAttachments":function($event){_vm.showAttachmentList = false}}})],1):_c('div',[_c('v-layout',{staticClass:"d-flex justify-center align-center flex-column",style:(`height: calc(100vh - 85px); background-color: ${
        Boolean(_vm.banner) ? _vm.banner['page_bg_color'] : '#C0C0C0'
      }`)},[_c('v-icon',{staticClass:"mb-3",attrs:{"size":"130","color":Boolean(_vm.banner) ? _vm.banner['page_text_color'] + '8A' : '#FFFFFF8A'}},[_vm._v(" mdi-face-agent ")]),_c('span',{staticClass:"text-h5",style:(`color: ${
          Boolean(_vm.banner) ? _vm.banner['page_text_color'] + '8A' : '#FFFFFF8A'
        }`)},[_vm._v(" SEM ATENDIMENTOS ")])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }