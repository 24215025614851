import axios from "axios";
import iliosState from "@/ilios.store";
import store from "@/store";

const initialState = () => ({
  apiState: iliosState.INIT,
  id: false,
  userName: false,
  userImage: "",
  usersList: false,
  teamId: null,
  teams: [],
  isSupervisor: false,
  agentId: null,
  partnerId: false,
  agentStatus: []
});

const state = initialState();

const getters = {
  getUserName(state) {
    return state.userName;
  },
  getUserImage(state) {
    return state.userImage;
  },
  getUsersList(state) {
    return state.usersList;
  },
  getUserId(state) {
    return state.id;
  },
  getUserTeamId(state) {
    return state.teamId;
  },
  getTeams(state) {
    return state.teams;
  },
  getIsSupervisor(state) {
    return state.isSupervisor;
  },
  getUserAgentId(state) {
    return state.agentId;
  },
  getAgentStatusList(state) {
    return state.agentStatus;
  },
};

const actions = {
  async getUserById(context, { domain, token }) {
    axios
      .get(process.env.VUE_APP_BASE_URL + "/iliosrest/get/res.users", {
        headers: {
          "Access-Token": token,
        },
        params: {
          db: process.env.VUE_APP_DATA_BASE,
          domain: domain,
          fields:
            "['name', 'id', 'partner_id', 'name_used_integration', 'holerite_code']",
          context: {
            lang: "pt_BR",
            tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
        },
      })
      .then(async (response) => {
        if (response["status"] == 200) {
          context.commit("SET_USER_DATA", {
            id: response.data.data[0].id,
            name: response.data.data[0].name,
            partnerId: response.data.data[0].partner_id[0],
          });
          store.commit("Login/SETLOGGEDIN", { isLogged: true });
          await context.dispatch("setUserTeam", {
            token: token,
            userId: response.data.data[0].id,
          });
        }
      })
      .catch((e) => {
        const messageError = e.response ? e.response.data.message : e;
        store.commit("Error/SET_MESSAGE_ERROR", {
          message: messageError,
        });
      });
  },
  async getAgentStatus(context, { token }) {
    await axios.get(process.env.VUE_APP_BASE_URL + "/api/agents/status", {
      headers: {
        "Access-Token": token,
      },
      params: {
        db: process.env.VUE_APP_DATA_BASE,
      },
    }).then((response) => {
      context.commit("SET_AGENT_STATUS_LIST", {
        list: response.data.status
      });
    }).catch((e) => {
      const messageError = e.response ? e.response.data.message : e;
      store.commit("Error/SET_MESSAGE_ERROR", {
        message: messageError,
      });
    });
  },
  async setUserTeam(context, { token, userId }) {
    let result = null;
    const url =
      process.env.VUE_APP_BASE_URL + "/get/chat/api/user_agent/" + userId;

    const config = {
      headers: {
        "Access-Token": token,
      },
    };
    await axios
      .get(url, config)
      .then((response) => {
        if (response["status"] == 200) {
          const [
            agentId,
            teams,
            isSupervisor,
            teamId
          ] = [
            response.data["data"]["agent_id"],
            response.data["data"]["teams"],
            response.data["data"]["is_supervisor"],
            response.data["data"]["teams"][0]["id"]
          ]

          result = {
            agentId: agentId,
            teamId: teamId,
          };

          context.commit("SET_USER_TEAM", {
            agentId: agentId,
            teamId: teamId,
            teams: teams
          });

          context.commit("SET_IS_SUPERVISOR", {
            isSupervisor: isSupervisor
          })
        }
      })
      .catch((e) => {
        const messageError = e.response ? e.response.data.message : e;
        store.commit("Error/SET_MESSAGE_ERROR", {
          message: messageError,
        });
      });
    return result;
  },
  reset({ commit }) {
    commit("RESET");
  },
};

const mutations = {
  SET_USER_TEAM(state, { teams, teamId, agentId }) {
    state.teams = teams;
    state.teamId = teamId;
    state.agentId = agentId;
  },
  SET_CURRENT_TEAM(state, { teamId }) {
    state.teamId = teamId
  },
  SET_IS_SUPERVISOR(state, { isSupervisor }) {
    state.isSupervisor = isSupervisor
  },
  SET_USER_DATA(state, { id, name, partnerId }) {
    state.id = id;
    state.userName = name;
    state.partnerId = partnerId;
  },
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },
  SET_API_STATE(state, { apiState }) {
    state.apiState = apiState;
  },
  SET_AGENT_STATUS_LIST(state, { list }) {
    state.agentStatus = list;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
