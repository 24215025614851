import axios from "axios";
import store from "@/store";
import router from "@/router";
import Swal from "sweetalert2";

const initialState = () => ({
  loadingAgents: false,
  loadingQueue: false,
  queueMenu: [
    // {
    //   name: "Atribuir para",
    //   action: 0,
    // },
    {
      name: "Assumir atendimento",
      action: 1
    },
  ],
  supervisorMenu: [
    {
      name: "Assumir atendimento",
      action: 0
    },
    {
      name: "Transferir atendimento",
      action: 1
    },
  ],
  agentMenu: [
    // {
    //   name: "Solicitar transferência",
    //   action: () => console.log("Solicitar transferência"),
    // },
    // {
    //   name: "Transferir para",
    //   action: () => console.log("Transferir para"),
    // },
  ],
});
const state = initialState();
const getters = {
  getLoadingAgents: (state) => state.loadingAgents,
  getLoadingQueue: (state) => state.loadingQueue,
  getQueueMenu: (state) => state.queueMenu,
  getSupervisorMenu: (state) => state.supervisorMenu,
  getAgentMenu: (state) => state.agentMenu,
};
const actions = {
};
const mutations = {
  LOAD_AGENTS(state, { load }) {
    state.loadingAgents = load;
  },
  LOAD_QUEUE(state, { load }) {
    state.loadingQueue = load;
  },
  CLEAN(state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },
};
export default {
  actions,
  state,
  getters,
  mutations,
  namespaced: true,
};
